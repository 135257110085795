import React, {
  ChangeEvent,
  Dispatch,
  FocusEventHandler,
  ReactElement,
  ReactNode,
  SetStateAction,
} from "react"
import { FormikTouched } from "formik"
import { ProductName } from "../../helpers/formHelpers"

export type TFormVariant =
  | "standart"
  | "newYear"
  | "kiberponedelnik"
  | "halvaDlyaGamerov"
  | "btsAuto"
  | "credit"
export type TImgTimerType = "halva_card" | "basic"

export type IFormPKW = {
  title?: React.ReactNode
  dataLayerName?: string
  formBtnText?: string
  hasBirth?: boolean
  orderNum?: string
  longTitle?: boolean
  additionalEventInDataLayer?: boolean
  longForm?: boolean
  withTimer?: boolean
  countDownTime?: number
  backgroundColor?: string
  productName?: string
  subTitle?: string | ReactElement
  emptyForm?: boolean
  noGridOnMobForOffice?: boolean
  onlyPKWPage?: boolean
  isGreenApprove?: boolean
  variant?: TFormVariant
  imgTimerType?: TImgTimerType
  timerDescription?: ReactNode
  fioPlaceHolder?: string
  backgroundSection?: string
  navigateVerifySMS?: boolean
  choiceProduct?: boolean
  setProductName?: Dispatch<SetStateAction<ProductName>>
  hasSelect?: boolean
  hasCityWithOffices?: boolean
  thanksPage?: TThanksPage
  progressBar?: boolean
  selectItems?: Record<"value" | "label", string>[]
  selectTitle?: string
  inputsColor?: "grey" | "white"
}

export const THANKS_PAGE = {
  SUCCESS: "/success/",
  SUCCESSPKW: "/success-pkw/",
} as const

export type TThanksPage = (typeof THANKS_PAGE)[keyof typeof THANKS_PAGE]

export interface FioData {
  surname: string | null
  name: string | null
  patronymic: string | null
}

export interface IDataCity {
  geo_lat: string // "55.7943584"
  geo_lon: string // "49.1114975"
  kladr_id: string // "1600000100014600012"
  fias_id?: string // "b32f0e1f-3f33-42a9-90a8-9f612e36f927"
  city: string // "Казань"
  city_with_type: string // "г Казань"
  settlement?: string
  region?: string // "Татарстан"
  flat?: string // "83" - квартира
  city_kladr_id: string // "1600000100000"
  settlement_kladr_id?: string // "16000001000146000"
  // ...
}

export interface FormValues {
  phone: string
  fio: {
    data: FioData
  }
  agree: boolean
  birthDate?: string
  localityAddress?: {
    value: string
    unrestricted_value: string
    data: IDataCity | null
  }
  deliveryOffice?: string
  region?: string
  inviteFriendFromWhom?: string
  customTextField?: string
}

export type IPersonalForm = {
  dataLayerName?: string
  hasBirth?: boolean
  hasCityWithOffices?: boolean
  onSubmit?: (value: Record<string, any>) => void
  formBtnText?: string
  noVerify?: boolean
  longForm?: boolean
  productName?: string
  inputsColor?: "white" | "grey"
  fullWidth?: boolean
  onlyPKWPage?: boolean
  isGreenApprove?: boolean
  variant?: TFormVariant
  fioPlaceHolder?: string
  navigateVerifySMS?: boolean
  choiceProduct?: boolean
  setProductName?: Dispatch<SetStateAction<ProductName>>
  hasSelect?: boolean
  thanksPage?: TThanksPage
  progressBar: boolean
  selectItems?: Record<"value" | "label", string>[]
  selectTitle?: string
}

export interface Response {
  requestId: string
  status: "pending" | "repeated" | "approve" | "reject" | "error"
  partnerExternalId: number
}

export type TFioField = {
  isGreenApprove?: boolean
  type: string
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  touched?: FormikTouched<{}>
  errors?: Record<string, any>
  id: string
  name: string

  handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  inputsColor?: "white" | "grey"
  fioPlaceHolder?: string
  fioValue?: {
    data: FioData
  }
  setFocusPhone: Dispatch<SetStateAction<boolean>>
}

export type TPhoneField = {
  focus: boolean
  setFocus: Dispatch<SetStateAction<boolean>>
  id: string
  name: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onPaste: (e: React.ClipboardEvent) => void
  handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  error?: string
  touched?: FormikTouched<{}>
  value: string
  inputsColor?: "white" | "grey"
  isFioFieldValidate?: boolean
  isGreenApprove?: boolean
}

interface Mode {
  forIndividuals: string[]
}
export interface OfficeData {
  id: string // deliveryOffice
  latitude: number
  longitude: number
  name: string
  address: string
  mode: Mode
  currencies: { personCash: { base: string; quote: string }[] }
}

export interface OfficeDataWithMoreInfo extends OfficeData {
  workHours?: { day: string; forIndividuals: string }[]
  isServicesToIndividuals?: boolean
  isServicesToLegalEntities?: boolean
}

export interface OfficeDataForSelect {
  value: string
  label: string
}
