import React, { useCallback, useEffect } from "react"
import clsx from "clsx"
import { StaticImage } from "gatsby-plugin-image"

import Container from "@ecom/ui/components/Container"
import { useLocation } from "@gatsbyjs/reach-router"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Timer from "../Timer/Timer"
import { PersonalForm } from "./Form/PersonalForm"
import { pushToDataLayerOnce } from "../../helpers/pushToDataLayerOnce"
import disableWebvisor from "../../utils/disableWebvisor"
import * as styles from "./formPKW.module.scss"
import { handleClickForm } from "../../helpers/WatcherDL/utils/handleClickForm"
import { IFormPKW } from "./types"

const defaultSubTitle = <>Быстрая&nbsp;и&nbsp;бесплатная доставка или&nbsp;самовывоз</>

export function FormPKW({
  title = "Оформите карту",
  isGreenApprove = false,
  dataLayerName,
  formBtnText,
  hasBirth = false,
  orderNum,
  longTitle = false,
  additionalEventInDataLayer = false,
  longForm = false,
  withTimer = false,
  countDownTime,
  backgroundColor,
  backgroundSection,
  productName,
  subTitle = defaultSubTitle,
  emptyForm = true,
  noGridOnMobForOffice = false,
  onlyPKWPage = false,
  imgTimerType = "basic",
  variant = "standart",
  navigateVerifySMS = true,
  timerDescription,
  fioPlaceHolder = "ФИО",
  choiceProduct = false,
  setProductName,
  hasSelect = false,
  hasCityWithOffices = false,
  thanksPage,
  progressBar = false,
  selectItems,
  selectTitle,
  inputsColor,
}: IFormPKW) {
  const location = useLocation()
  const isDesktop = useMediaQuery("(min-width:960px)")

  // отключение записи ПД в вебвизоре
  const ref = useCallback((node: any) => {
    disableWebvisor(node)
  }, [])

  const handleClick = (e: any) => {
    handleClickForm()

    if (additionalEventInDataLayer && e.target.type === "checkbox") {
      pushToDataLayerOnce("aggrement", { event: "buttonClick", name: "aggrement" })
    }
  }

  useEffect(() => {
    const handleScrollToForm = () => {
      const element = document.getElementById("app")
      if (element) {
        const offset = 120
        const position = window?.scrollY - offset
        window?.scrollTo({ top: position })
      }
    }
    if (isDesktop && location?.hash === "#app") {
      handleScrollToForm()
    }
  }, [isDesktop, location])

  return (
    <section
      style={{ background: backgroundSection }}
      className={clsx(
        styles.section,
        longTitle && styles.sectionLongTitle,
        withTimer && styles.sectionWithTimer,
        backgroundColor && styles.backgroundSection,
        !emptyForm && styles.emptyForm,
        styles[variant]
      )}
      data-exclude={orderNum}
      id="formPkw"
    >
      <div className={styles.anchor} id="app" />
      <Container>
        <div
          style={{ background: backgroundColor }}
          className={clsx(
            styles.form,
            longTitle && styles.formLongTitle,
            withTimer && styles.formWithTimer,
            !emptyForm && styles.emptyForm,
            noGridOnMobForOffice && styles.noGridOnMobForOffice
          )}
        >
          {title && (
            <div className={longTitle ? styles.infoLongTitle : styles.info}>
              <h3 className={clsx(styles.head, longTitle && styles.headLongTitle)} ref={ref}>
                {title}
              </h3>
              <p className={clsx(longTitle ? styles.descrLongTitle : styles.descr)}>{subTitle}</p>
            </div>
          )}
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            className={styles.formContainer}
            onClick={(e) => handleClick(e)}
            onKeyDown={(e) => handleClick(e)}
          >
            <PersonalForm
              hasCityWithOffices={hasCityWithOffices}
              progressBar={progressBar}
              choiceProduct={choiceProduct}
              setProductName={setProductName}
              dataLayerName={dataLayerName}
              formBtnText={formBtnText}
              hasBirth={hasBirth}
              longForm={longForm}
              productName={productName}
              onlyPKWPage={onlyPKWPage}
              variant={variant}
              isGreenApprove={isGreenApprove}
              fioPlaceHolder={fioPlaceHolder}
              navigateVerifySMS={navigateVerifySMS}
              hasSelect={hasSelect}
              thanksPage={thanksPage}
              selectItems={selectItems}
              selectTitle={selectTitle}
              inputsColor={inputsColor}
            />
          </div>
          {emptyForm && (
            <div>
              {withTimer ? (
                <div className={styles.timer}>
                  <Timer
                    imgTimerType={imgTimerType}
                    countDownTime={countDownTime}
                    isNewYear={variant === "newYear"}
                    timerDescription={timerDescription}
                  />
                </div>
              ) : (
                <StaticImage
                  className={styles.img}
                  alt="women"
                  src="./images/women.png"
                  placeholder="blurred"
                  formats={["auto", "webp", "avif"]}
                />
              )}
            </div>
          )}
        </div>
      </Container>
    </section>
  )
}
