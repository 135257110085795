// extracted by mini-css-extract-plugin
export var acceptmentError = "personalForm-module--acceptmentError--rkVt3";
export var btn = "personalForm-module--btn--LNU3s";
export var btnWidth = "personalForm-module--btnWidth--rNYCp";
export var customTextFieldError = "personalForm-module--customTextFieldError--iVK7l";
export var form = "personalForm-module--form--CGOPT";
export var halvaDlyaGamerov = "personalForm-module--halvaDlyaGamerov--4s1t9";
export var kiberponedelnik = "personalForm-module--kiberponedelnik--Nn9e4";
export var listPaper = "personalForm-module--listPaper--Qofvp";
export var listProps = "personalForm-module--listProps--RjVwi";
export var pickupContainer = "personalForm-module--pickupContainer--1ntNM";
export var selectField = "personalForm-module--selectField---d3lg";
export var selectFieldOffice = "personalForm-module--selectFieldOffice--WZ-8c";
export var selectFieldOther = "personalForm-module--selectFieldOther--VC06O";