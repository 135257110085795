import { boolean, mixed, object, string } from "yup"
import {
  birthRegExp,
  isCyrillic,
  isNumberOrSpecChars,
  isNumberOrSpecCharsWithoutSpace,
  isItMoreThanTwoWords,
  isMaxOfOneHyphen,
  phoneRegExp,
} from "../../../helpers/formHelpers"

export const FormSchema = (
  hasBirth: boolean,
  hasSelect: boolean,
  hasCityWithOffices: boolean,
  selectItems: any
) =>
  object().shape({
    phone: string()
      .matches(phoneRegExp, "Пожалуйста, укажите корректный номер")
      .required("Введите номер телефона полностью"),
    fio: object().shape({
      data: object().shape({
        surname: mixed()
          .required("Введите фамилию и имя")
          .test(
            "surname isMaxOfOneHyphen",
            "Неверно введена фамилия",
            (value) => !isMaxOfOneHyphen(value)
          )
          .test(
            "surname isNumberOrSpecChars",
            "Фамилия не должна содержать символы или числа",
            (value) => !isNumberOrSpecChars(value)
          )
          .test("surname isCyrillic", "Фамилия не должна содержать латиницу", (value) =>
            isCyrillic(value)
          ),
        name: mixed()
          .required("Введите фамилию и имя")
          .test("name isMaxOfOneHyphen", "Неверно введено имя", (value) => !isMaxOfOneHyphen(value))
          .test(
            "name",
            "Имя не должно содержать символы или числа",
            (value) => !isNumberOrSpecChars(value)
          )
          .test("name isCyrillic", "Имя не должно содержать латиницу", (value) =>
            isCyrillic(value)
          ),
        patronymic: mixed()
          .test(
            "patronymuic isItMoreThanTwoWords",
            "Отчество не должно состоять более чем из двух слов",
            (value) => !isItMoreThanTwoWords(value)
          )
          .test(
            "patronymic",
            "Отчество не должно содержать символы или числа",
            (value) => !isNumberOrSpecCharsWithoutSpace(value)
          )
          .test("patronymic isCyrillic", "Отчество не должно содержать латиницу", (value) =>
            isCyrillic(value)
          ),
      }),
    }),
    ...(hasBirth && {
      birthDate: string()
        .matches(birthRegExp, "Пожалуйста, укажите корректную дату")
        .required("Пожалуйста, укажите дату рождения"),
    }),
    ...(hasCityWithOffices && {
      localityAddress: object().shape({
        value: string()
          .required("Введите город")
          .test("city isCyrillic", "Город не должен содержать латиницу", (value) =>
            isCyrillic(value!)
          ),
        data: object()
          .nullable()
          .test(
            "is-not-null",
            "Пожалуйста, выберите значение из списка",
            (value) => value !== null
          ),
      }),
      deliveryOffice: string().required("Пожалуйста, выберите значение из списка").default(""),
    }),
    agree: boolean().required().oneOf([true], "Необходимо согласие с условиями"),
    ...(hasSelect && {
      inviteFriendFromWhom: string().required("Выберите значение из списка").default(""),
      customTextField: string().when("inviteFriendFromWhom", {
        is: (value: string) =>
          value &&
          selectItems.map((item: { value: string; label: string }) => item.value).includes(value),
        then: string().optional(),
        otherwise: string().required("Пожалуйста, введите вариант ответа"),
      }),
    }),
  })
